/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { BaseContactsField, CompanyDebtsSummary } from '@fingo/lib/graphql/customers/fragment';

export const CONTACTABILITY_MASTER_ENTITIES = gql`
  query contactabilitymasterEntities(
    $first: Int
    $offset: Int
    $globalFilter: String
    $orderBy: String
    $contactType: String
    $showHidden: Boolean
    $company_ControlGroupDetail_Isnull: Boolean
    $company_ControlGroupDetail_MailParameter_Mail_Id: ID
    $distinct: Boolean
    $validSiiCredentials: Boolean
  ) {
    getMasterEntities(
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      orderBy: $orderBy
      company_ControlGroupDetail_MailParameter_Mail_Id: $company_ControlGroupDetail_MailParameter_Mail_Id,
      company_ControlGroupDetail_Isnull: $company_ControlGroupDetail_Isnull
      distinct: $distinct,
      validSiiCredentials: $validSiiCredentials
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        node {
          id
          rut
          name
          displayNationalIdentifier
          contacts(
            first: 1
            contactType_Code: $contactType
            showHidden: $showHidden
          ) {
              edges {
                node {
                  ...BaseContactsField
                }
              }
            }
          company {
            id
            controlGroupDetail {
              id
              mailParameter {
                id
                mail {
                  id
                  emailName
                }
                country {
                  id
                  name
                }
                requestingPlatform {
                  id
                  code
                }
                mailTemplate {
                  version
                  template
                }
              }
            }
          }
          riskBlacklist {
            id
          }
          ...CompanyDebtsSummary
        }
      }
    }
  }
  ${CompanyDebtsSummary}
  ${BaseContactsField}
`;
